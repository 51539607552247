/*==========  Mobile First Method  ==========*/
html {
  font-size: 14px;
}

#slide {
  h2 {
	font-size: 1rem;
  }

  p {
	font-size: .8rem;
  }
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 769px) {

  header {
	position: sticky;
	top: 0;
	z-index: 100;
	background: #fff;
	transition: 0.3s min-height;
	box-shadow: 0 4px 3px rgba(0,0,0,.1);

	&.compressed {
	  /*	  #logo h1 {
			  font-size: 2rem;
			}
	  */
	}

	> .container:first-of-type {
	  padding-top: .5rem;
	  padding-bottom: .5rem;
	}

	.bg-menu {
	  background: $menuBg;
	  /*border-bottom: 1px solid $menuColorSeparator;*/
	}

	#logo {
	  img {
		height: 60px;
		width: auto;
	  }

	  h1 {
		color: $primary;
		font-size: 2.5rem;
		transition: 0.3s all;
	  }
	}

  }

  #slide {
	h2 {
	  font-size: 3rem;
	}

	p {
	  font-size: 2rem;
	}
  }

  .icons {
	display: flex;

	a {
	  background: $boxBg;
	  width: 2.4rem;
	  height: 2.4rem;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  border-radius: 100%;
	  margin-left: 0.5rem;
	  color: $boxColor;

	  &:hover {
		color: #000;
	  }
	}

	i {
	  font-size: 1.2rem;
	}
  }

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  html {
	font-size: 16px;
  }
  #slide h1{font-size: 50px;}
  #slide p{font-size: 34px;}
  #slide p span{font-size: 50px;}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1400px) {
  html {
	font-size: 16px;
  }
}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .nivo-caption .tbl .tbl-cell {
	vertical-align: middle;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {

  body {
	padding-top: 4rem;
  }

  .container {
	min-width: 100%;
  }

  .jumbotron {
	padding: 0;
	margin: 0;
  }
  .jumbotron h1 {
	font-size: 2rem;
	padding: 1rem;
  }

  footer .column {
	width: 100% !important;
  }

  .idiomes li a {
	padding: 0.4rem;
  }

}


/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}

@media (min-width: 1200px) {
  .container {
	max-width: 1200px;
  }
}
