html {
	font-size: 14px;
	font-family: "Open Sans", serif;
	color: #424242;
}

html,
body {
	width: 100%;
	position: relative;
	margin: 0;
	padding: 0;
}

body {
	color: #424242;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;

	a {
		color: inherit;
	}
}

ul,
p {
	margin: 0;
	padding: 0;
}

.wow {
	visibility: hidden;
}

a {
	color: $primary;
	text-decoration: none;
}

header {
	a:hover {
		text-decoration: none;
	}
}

main {
	overflow: hidden;
	clear: both;
	position: relative;
	// padding-bottom: 2.5rem;
	min-height: 80vh;

	ul {
		margin: 0 0 0 2.5rem;
		padding: 0;
	}
}

.jumbotron {
	padding: 30px 0;
	border-radius: 0;
	border: none;
	margin: 0;
	color: $primary;

	h1 {
		color: currentColor;
		text-align: center;
		font-size: 2.5rem;
		margin: 0;
		font-weight: 400;
	}
}

footer {
	background: white;
	color: white;
	padding: 3rem 0;

	p {
		margin: 0;
		padding: 0;
	}

	a {
		color: black;
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
		color: $primary;
	}

	a i {
		color: inherit;
	}
	.column {
		display: flex;
		flex-direction: column;
		color: #000;
	}
}

.idiomes {
	list-style: none;

	li {
		float: left;
		display: inline;
		margin: 0 3px;
	}

	li a {
		color: #333;
	}

	li a.selected {
		color: #333;
		font-weight: bold;
	}
}

h5.data{font-size: 16px;margin:0;padding: 0;font-style: italic;margin-bottom: 10px;}
.col-data{min-width: 70px;margin-right: 30px;}
.data_box{position: relative;overflow: hidden;clear: both;text-align: center;background:#EEEEEE;padding:10px 0;height: auto !important;}
.data_box span{position: relative;clear: bottom;text-align: center;display: block;color: #000;}
.data_box span.dia{font-size: 32px;font-weight: bold;line-height: 35px;}
.data_box span.mes{text-transform: uppercase;line-height: 15px;font-size: 17px;}
.data_box span.any{font-size: 15px;}

#contingut{position: relative;overflow: hidden;clear: both;min-height: 300px;padding-bottom: 70px;}
#contingut h4{margin-bottom: 5px;color: #333;font-weight: bold;font-size: 18px;}
#contingut h4 a{color:#333;}

.jumbotron h1{color:#029946 !important;text-align: center;font-size: 28px;margin:5px 0;font-weight: 800;}

#slide h2{font-size: 18px;font-weight:300;color:#fff !important;text-shadow: 2px 2px 2px #222;}
#slide p{font-size: 18px;font-weight: 300;color:#fff !important;text-shadow: 2px 2px 2px #222;font-family: Roboto;}
#slide span{color:#76FF03;font-weight: 800;}
