// Colors
$primary: #029946;
$secondary: #E0E0E0;
$greylight: #dcdcdc;
$black: #000000;
$login: $primary;

// Menú
$menuBg: #fff;
$menuColor: #202124;
$menuColorHover: $primary;
$menuColorSelected: $primary;
$menuColorSeparator: darken($menuBg, 20%);
$menuBgSubmenu: #fff;

// Components
$boxBg: #f8f9fa;
$boxColorSeparator: #dadce0;
$boxColor: #202124;

// Components
$border-radius-sm: 0.6rem;
$border-radius-md: 1.5rem;
